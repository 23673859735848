import React, { useContext, useEffect } from "react";
import { StyledGridRow } from "../../commons/Grid";
import { toast } from "react-toastify";
import { DataContext } from "../../contexts/DataContext";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { 
  StyledContactForm, 
  StyledContactFormSubmit, 
} from "./style.jsx";
import {
  StyledForm,
  StyledFormFieldset,
  StyledFormBlock,
  StyledFormInput,
  StyledFormSubmit,
  StyledFormCheckbox,
  StyledFormCheckboxInput,
  StyledFormCheckboxCheckmark,
} from "../../commons/Form";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import usePipeform from "../../hooks/usePipeform";

export default () => {
  toast.configure();
  const { labels } = useContext(DataContext);
  const { sendPipeformBox } = usePipeform();

  const formSchema = z.object({ 
    name: z.string().min(1),
    email: z.string().email().min(1),
    phone: z.string().min(1),
    shopType: z.string().nullish(),
    shopAddress: z.string().nullish(),
    message: z.string().nullish(),
    privacy: z.literal(true),
    acceptMarketing: z.boolean(),
  });

  const {
    handleSubmit,
    formState: {
      errors,
    },
    register,
    setFocus,
    reset,
  } = useForm({ resolver: zodResolver(formSchema) });

  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const fieldName = keys[0];
      const name = errors[fieldName].ref?.placeholder ?? fieldName;
      setFocus(fieldName);
      toast.error(`${name}: ${errors[fieldName].message}`,{
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER, 
      });
    }
  }, [setFocus, errors]);

  const trackError = (message) => {
    window.dataLayer.push({
      event: "customError",
      custom: {
        errorSource: "Pipedrive",
        errorMessage: `ContactFormPipeBox: ${message}`,
      },
    });
  };

  return (
    <StyledContactForm id="contactForm">
      <StyledGridRow>
        <StyledForm
          id="contact3Bee"
          Validate
          onSubmit={handleSubmit((params) => {
            sendPipeformBox(params)
              .then((response) => {
                if (response.status === 202) {
                  toast.success(labels.positiveMessages, {
                    containerId: "Alert",
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  window.dataLayer.push({
                    event: "B2BContact",
                  });
                  reset();
                } else {
                  toast.error(labels.negativeMessages, {
                    containerId: "Alert",
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  trackError(JSON.stringify(params));
                }
              })
              .catch(function () {
                toast.error(labels.negativeMessages, {
                  containerId: "Alert",
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                trackError(JSON.stringify(params));
              });
            
            return false;
          })}
        >
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-user"
                type="text"
                placeholder={labels.NAME_SURNAME}
                required
                {...register("name")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-email"
                type="email"
                required
                placeholder={labels.MAIL_ADDRESS}
                {...register("email")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-phone"
                type="tel"
                required
                placeholder={labels.PHONE_MOBILE}
                {...register("phone")}
              />
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-shop-type"
                type="text"
                placeholder={labels.shopType}
                {...register("shopType")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormInput
                id="contacts-shop-address"
                type="text"
                placeholder={labels.shopAddress}
                {...register("shopAddress")}
              />
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledFormFieldset>
            <StyledFormBlock>
              <StyledFormInput
                as="textarea"
                rows={3}
                id="contacts-request"
                placeholder={labels.REQUEST}
                {...register("message")}
              />
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormCheckbox>
                <span dangerouslySetInnerHTML={{ __html: labels.acceptPrivacy }}></span>
                <StyledFormCheckboxInput
                  {...register("privacy")}
                  required
                />
                <StyledFormCheckboxCheckmark />
              </StyledFormCheckbox>
            </StyledFormBlock>
            <StyledFormBlock>
              <StyledFormCheckbox>
                {labels.acceptMarketing}
                <StyledFormCheckboxInput 
                  {...register("acceptMarketing")}
                />
                <StyledFormCheckboxCheckmark />
              </StyledFormCheckbox>
            </StyledFormBlock>
          </StyledFormFieldset>
          <StyledContactFormSubmit>
            <StyledFormSubmit type="submit">
              {labels.SEND_REQUEST}
              <Icon icon={icons.arrowRight} color={Colors.white} />
            </StyledFormSubmit>
          </StyledContactFormSubmit>
        </StyledForm>
      </StyledGridRow>
    </StyledContactForm>
  );
};
